import React ,{forwardRef, Fragment, useEffect, useState} from 'react'
import {
    Grid,
    LinearProgress,
    Select,
    OutlinedInput,
    MenuItem,
    Button,
    Tooltip,
    Card,
    CardContent,
    Typography
  } from "@material-ui/core";

// components
//import PageTitle from "../../components/PageTitle/PageTitle";
import FormSearchPurchaseOrder from "../../components/FormSearchPurchaseOrder/FormSearchPurchaseOrder";
import ListPurchaseOrder from "../../components/ListPuchaseOrder/ListPurchaseOrder";
//

//css
import {Main} from './style';

//datetime
import momentTz from 'moment-timezone';
import moment from 'moment';

import MuiAlert from '@material-ui/lab/Alert';
import { Link as RouterLink } from 'react-router-dom';
//import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";

//services
import registrationService from '../../services/registration.service';



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function ApprovalPO(props) {

  let history = useHistory(); 
  const navigateToNew = () => history.push('/app/registration-add');//eg.history.push('/login');
  


  const classes = Main();
  //FormSearch
  const [formSearch, setFormSearch] = useState({
    status: '',
    search: '',
    start_date: moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00',
    end_date: moment(new Date()).format('YYYY-MM-DD') + ' 23:55:00',
    round_id: ''
  });
  const [ startDate, setStartDate ] = useState( moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00');


  const [ endDate, setEndDate ] = useState(moment(new Date()).format('YYYY-MM-DD') + ' 23:55:00');
 
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEnd, setIsOpenEnd] = useState(false);
  const [statusPO, setStatusPO] = useState(0);
  const [dataPurchase,getDataPurchase] = useState([]);
  const [pagenoPurchase,getPageNoPurchase] = useState(1);
  const [countPage,getCountPage] = useState(10);
  
  const handleSearchStart = event => {
    const target = event.target.name;
    const input = event.target.value;
    
    if(target==='dateStart'){
      setStartDate(moment(input).format('YYYY-MM-DD') + ' 00:00:00')
    }
    if(target==='dateEnd'){
      setEndDate(moment(input).format('YYYY-MM-DD')+ ' 23:55:00')
    }

   
    //console.log(event);
    //showSnackbar('handleSearchStart');
    getCountPage(10);
   // getAllPruchase({startDate:startDate,endDate:endDate,status_po:statusPO,event:'handleSearchStart'});
  };

  const handleSearchEnd = event => {
    const target = event.target.name;
    const input = moment(event.target.value).format('YYYY-MM-DD') + ' 23:55:00';
    
    if(target==='dateEnd'){
      setEndDate(input)
    }

   
    //console.log(event);
    //showSnackbar('handleSearchEnd');
    getCountPage(10);
   // getAllPruchase({startDate:startDate,endDate:endDate,status_po:statusPO,event:'handleSearchEnd'});
  };

  const handleSearch = event => {
    const target = event.target.name;
    const input = event.target.value;
    
    setStatusPO(input);
    //console.log(event);
    //showSnackbar('handleSearch');
    getCountPage(10);
    //getAllPruchase({startDate:startDate,endDate:endDate,status_po:statusPO,event:'handleSearch'});
  };


  const getAllPruchase = async (params) => { 
    //showSnackbar('params : ' + JSON.stringify(params));

    const configs={
      page: pagenoPurchase,
      per_page:10,
      start_date:params.startDate,
      end_date:params.endDate
      
    };

    if(params.status_po >0){
      configs.status_po = params.status_po;
    }

    return await registrationService.getlist(configs).then(
      (response)=>{
          const allBranch = response; 
          getPageNoPurchase(response.current_page);
          getCountPage(response.total);
               
          console.log(response.data);    
          getDataPurchase(allBranch);
         
      }).catch(error => console.error(`Error: $(error)`));
  };

  useEffect(() => {
    // Update the document title using the browser API
    //showSnackbar('useEffect');
    getAllPruchase({startDate:startDate,endDate:endDate,status_po:statusPO,event:'useEffect'});
  },[startDate,endDate,statusPO]);

  const handleChangePage = (newPage) => {
    //console.log(newPage);
    getPageNoPurchase(newPage);
    //showSnackbar('handleChangePage');
    getAllPruchase({startDate:startDate,endDate:endDate,status_po:statusPO,event:'handleChangePage'});
  }

  const downloadClick = () => {
    
  }

  const downloadOldClick = () => {
    
  }

  return (
    <div className={classes.root}>
        <div className={classes.row}>
         
      </div>
      
    
     
      <div className={classes.row}>
          <FormSearchPurchaseOrder
            classes={classes}
            moment={moment}
            setIsOpen={setIsOpen}
            startDate={startDate}
            isOpen={isOpen}
            handleStartDate={handleSearchStart}
            setIsOpenEnd={setIsOpenEnd}
            endDate={endDate}
            isOpenEnd={isOpenEnd}
            handleEndDate={handleSearchEnd}
            handleSearch={handleSearch}
            formSearch={formSearch}
            rounds={props.rounds}
          />
        </div>     
        
        <div className={classes.row}>
          <ListPurchaseOrder
            classes={classes}
            purchaseOrders={dataPurchase}
            propsHandleChangePage={(newPage) => handleChangePage(newPage)}
            downloadClick={() => downloadClick()}
            downloadOldClick={() => downloadOldClick()}
          />
        </div>

    </div> 


  )
}
