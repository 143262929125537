/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  Paper,
  IconButton,
  InputBase,
  FormControl,
  InputLabel,
  Select,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CalendarToday, Search as SearchIcon } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';

import { getListPOAOG } from '../../services/po.service';
import { ListApprovalPurchaseOrder } from './components';
import { useSnackbar } from '../../shared/useSnackbar';

const rowPerPage = 10;
const ApprovalPO = () => {
  const classes = useStyles();
  const { createSnackbar } = useSnackbar();

  const [page, setPage] = useState(1);
  const [dataAPI, setDataAPI] = useState(null);
  const [startDate, setStartDate] = useState({
    startDate: new Date(),
  });
  const [endDate, setEndDate] = useState({
    endDate: new Date(),
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEnd, setIsOpenEnd] = useState(false);
  const [formSearch, setFormSearch] = useState({
    status: '',
    search: '',
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
  });

  const showSnackbar = (msg, theme) => {
    createSnackbar({
      message: msg,
      dismissable: false,
      theme: theme,
      sticky: false,
    });
  };

  const handleStartDate = (input) => {
    setStartDate((startDate) => ({
      ...startDate,
      startDate: input,
    }));
    setFormSearch(() => ({
      ...formSearch,
      start_date: moment(input).format('YYYY-MM-DD'),
    }));
  };

  const handleEndDate = (input) => {
    setEndDate((endDate) => ({
      ...endDate,
      endDate: input,
    }));
    setFormSearch(() => ({
      ...formSearch,
      end_date: moment(input).format('YYYY-MM-DD'),
    }));
  };

  const handleSearch = (event) => {
    const target = event.target.name;
    event.persist();
    setFormSearch((formSearch) => ({
      ...formSearch,
      [target]: event.target.value,
    }));
  };

  const getPurchaseOrder = async () => {
    try {
      const data = await getListPOAOG({
        sort_by: 'created_at',
        order_by: 'desc',
        status_po: formSearch.status,
        start_date: formSearch.start_date,
        end_date: formSearch.end_date,
        invoice_id: formSearch.name,
        page: page,
        per_page: rowPerPage,
      });
      console.log('data', data);
      setDataAPI(data);
    } catch (e) {
      console.error(e.response.data.msg_str);
      showSnackbar(e.response.msg_str);
    }
  };

  useEffect(() => {
    getPurchaseOrder();
  }, [
    formSearch.status,
    formSearch.start_date,
    formSearch.end_date,
    page,
    formSearch.search,
  ]);

  return (
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant='h5'>
                <b>Purchase Order</b>
              </Typography>
            </Grid>
          </Grid>
        </div>

        <Paper className={classes.paperContent}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TextField
                  onClick={() => setIsOpen(true)}
                  value={moment(startDate.startDate).format(
                    "dddd DD MMMM YYYY"
                  )}
                  fullWidth
                  className={classes.margin}
                  label="Tanggal Awal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" disablePointerEvents>
                        <CalendarToday />
                      </InputAdornment>
                    ),
                  }}
                />
                <DatePicker
                  fullWidth
                  open={isOpen}
                  onOpen={() => setIsOpen(true)}
                  onClose={() => setIsOpen(false)}
                  disableFuture
                  variant="outlined"
                  name="start_date"
                  format="dd MMMM yyyy HH:mm"
                  value={startDate.startDate}
                  onChange={(e) => handleStartDate(e)}
                  TextFieldComponent={() => null}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={4} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TextField
                  onClick={() => setIsOpenEnd(true)}
                  value={moment(endDate.endDate).format("dddd DD MMMM YYYY")}
                  fullWidth
                  className={classes.margin}
                  label="Tanggal Akhir"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" disablePointerEvents>
                        <CalendarToday />
                      </InputAdornment>
                    ),
                  }}
                />
                <DatePicker
                  fullWidth
                  open={isOpenEnd}
                  onOpen={() => setIsOpenEnd(true)}
                  onClose={() => setIsOpenEnd(false)}
                  disableFuture
                  variant="outlined"
                  name="end_date"
                  format="dd MMMM yyyy HH:mm"
                  value={endDate.endDate}
                  onChange={handleEndDate}
                  TextFieldComponent={() => null}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Status
                </InputLabel>
                <Select
                  fullWidth
                  native
                  defaultValue={formSearch.status}
                  onChange={handleSearch}
                  label="Status"
                  inputProps={{
                    name: "status",
                    id: "age-native-label-placeholder",
                  }}
                  name="status"
                >
                  <option value={""}>Semua</option>
                  <option value={"1"}>Menunggu Konfirmasi</option>
                  <option value={"2"}>Diproses</option>
                  <option value={"3"}>Dikirim</option>
                  <option value={"4"}>Sampai</option>
                  <option value={"99"}>Ditolak</option>
                  <option value={"98"}>Expired</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        <div className={classes.row}>
          <Grid container justify="flex-end">
            <Grid item md={3} xs={12}>
              <Paper component="form" className={classes.searchRoot}>
                <InputBase
                  className={classes.input}
                  placeholder="Cari Kode Unik Invoice"
                  inputProps={{ "aria-label": "Cari Kode Unik Invoice" }}
                  defaultValue={formSearch.search}
                  name={"search"}
                  onChange={handleSearch}
                />
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  disabled
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <div className={classes.row}>
            <Paper className={classes.paperContent}>
              <div className={classes.paperPadding}>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography className={classes.tagMenu} variant={"h5"}>
                      History Purchase Order
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2} justify="space-around">
                      <Grid item>
                        <Button
                          variant={"contained"}
                          size={"medium"}
                          className={classes.btnSuccess}
                          // onClick={() => download()}
                          disabled={true}
                        >
                          Download Format 1
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant={"contained"}
                          size={"medium"}
                          className={classes.buttonWarning}
                          // onClick={() => downloadOld()}
                          disabled={true}
                        >
                          Download Format 2
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <ListApprovalPurchaseOrder
                purchaseOrders={dataAPI}
                classes={classes}
                page={page}
                rowPerPage={rowPerPage}
                setPage={setPage}
              />
            </Paper>
          </div>
        </div>
      </div>
  );
};

export default ApprovalPO;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paperContent: {
    padding: theme.spacing(3),
  },
  paperPadding: {
    marginBottom: theme.spacing(2),
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  tagMenu: {
    fontWeight: 'bold',
  },
  btnSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    textTransform: 'None',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  buttonWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    textTransform: 'None',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    },
  },
  chipWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
  },
  chipError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white,
  },
  chipSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
  },
  chipPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },
  chipSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
  },
}));
