import * as actionTypes from './types';
import axios from '../axios-orders';
import { setAlert } from './alert'

export const fetchMembersStart = () => {
  return {
    type: actionTypes.FETCH_MEMBERS_START
  }
}

export const fetchMembersSuccess = (members) => {
  return {
    type: actionTypes.FETCH_MEMBERS_SUCCESS,
    members: {
      members,
    }
  }
}

export const fetchMembersFail = (error) => {
  return {
    type: actionTypes.FETCH_MEMBERS_FAIL,
    error: error
  }
}

export const fetchMember = (page, formSearch) => {
  let param = '&search_by='+formSearch.search_by+'&search_value='+formSearch.search_value+'&status='+formSearch.status;
  return dispatch => {
    dispatch(fetchMembersStart())
    axios.get(`user/member?page=${page}&page_size=10&order_by=id_agent&order_type=desc`+param,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchMembersSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchMembersFail(err.response.data.message))
        dispatch(setAlert("something went wrong", "error"))
      })
  }
}