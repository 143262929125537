import React, {Fragment, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Loading, TablePaginationActions} from '../../../components/UI';
import * as actions from '../../../actions';

import {
  TableFooter,
} from '@material-ui/core';
import {connect} from "react-redux";
import {isEmpty} from "../../../shared/utility";
import {TableBodyComponents} from "./components";

const columns = [
  { id: 'nama_lengkap', label: 'Nama Lengkap', minWidth: 100 },
  { id: 'status_anggota', label: 'Status Member', minWidth: 100 },
  { id: 'no_id', label: 'No ID', minWidth: 200 },
  { id: 'email', label: 'Email', minWidth: 100 },
  { id: 'no_whatsapp', label: 'No WhatsApp', minWidth: 100 },
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ListMember = (props) => {
  const classes = useStyles();
  const {formSearch,onFetchMembers,members,loading} = props;

  useEffect(() => {
    const timer = setTimeout(() => {
      onFetchMembers(1, formSearch);
    }, 1000)

    return () => clearTimeout(timer)
  },[onFetchMembers, formSearch]);

  const handleChangePage = (event, newPage) => {
    onFetchMembers(newPage, formSearch);
  }

  let tableBody = '';

  if(!isEmpty(members)){
    const page = members.members.current_page - 1;
    const rowsPerPage = 10;
    const countRows = members.members.total_item
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, countRows - page * rowsPerPage)
    // console.log(page, rowsPerPage, countRows, emptyRows);

    tableBody = (
      <Fragment>
        <TableBody>
          {members.members.data.map((member, index) => (
            <TableBodyComponents
              member={member}
              key={member.id_agent}
              index={index}
              // edited={() => props.edit(kurir)}
              // deleted={() => props.hapus(kurir)}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              colSpan={8}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (loading ?  <Loading/> :
    <Fragment>
      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>)

}

const mapStateToProps = state => {
  return {
    members: state.member.members,
    loading: state.member.loading
  };
};

const mapDispatchToProps = dispatch => {
  return{
    onFetchMembers: (page, formSearch) => dispatch(actions.fetchMember(page, formSearch))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListMember);