import axios from 'axios';
//import { setAuthHeader } from './functions';


export const getApi = async (url, params) => {
  //setAuthHeader();
  const tokens = sessionStorage.getItem('access_token');
  
  let config = {
    headers: {Authorization: `Bearer ${tokens}`},
    params: params,
  };

  
  const result = await axios.get(url, config);
  
  return result.data;
};

export const postApi = async (url, params) => {
  //setAuthHeader();
  const tokens = sessionStorage.getItem('access_token');
  const result = await axios({
    url: url,
    method: "POST",
    data: params,
    loading: true,
    headers: { 
      //'Content-Type': 'multipart/form-data', 
      'Accept' : 'application/json', 
      'Authorization':`Bearer ${tokens}`
    }}).catch(function (error) {
       throw error;
    });

  return result.data;
};

export const getApiNoAuth = async (url, params) => {
  const result = await axios(url, params);
  return result.data;
};

export const postApiNoAuth = async (url, params) => {
  //const result = await axios.post(url, params);

  const result = await axios({
    url: url,
    method: "POST",
    data: params,
    loading: true,
    headers: { 
      'Content-Type': 'multipart/form-data', 
      'Accept' : 'application/json', 
    }
});

  return result.data;
};


export const fetchApi = async (url,exec, params)=> {
  const tokens = sessionStorage.getItem('access_token');
  try {
    const res = await fetch(url, {
      method: exec,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": "token-value",
        'Authorization':`Bearer ${tokens}`,
      },
      body: JSON.stringify(params),
    });
    if (!res.ok) {
      const message = `An error has occured: ${res.status} - ${res.statusText}`;
      throw new Error(message);
    }
    const data = await res.json();
    const result = {
      status: res.status + "-" + res.statusText,
      headers: {
        "Content-Type": res.headers.get("Content-Type"),
        "Content-Length": res.headers.get("Content-Length"),
      },
      data: data,
    };
    return result;
  } catch (err) {
    throw new Error(err.message);
  }
}


export const putApi = async (url, params) => {
  //setAuthHeader();
  const tokens = sessionStorage.getItem('access_token');
  const result = await axios({
    url: url,
    method: "PUT",
    data: params,
    loading: true,
    headers: { 
      //'Content-Type': 'multipart/form-data', 
      'Accept' : 'application/json', 
      'Authorization':`Bearer ${tokens}`
    }}).catch(function (error) {
       throw error;
    });

  return result.data;
};